body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body {
  overflow-x: hidden !important;
  font-family: 'Barlow', sans-serif !important;
  background: #F5F5F5 !important; }

@media screen and (min-width: 1921px) {
  html,
  body {
    font-size: 20px; } }

@media screen and (max-width: 1920px) and (min-width: 1681px) {
  html,
  body {
    font-size: 20px; } }

@media screen and (max-width: 1680px) and (min-width: 1600px) {
  html,
  body {
    font-size: 20px; } }

@media screen and (max-width: 1599px) and (min-width: 1360px) {
  html,
  body {
    font-size: 20px; } }

@media screen and (max-width: 1359px) and (min-width: 1024px) {
  html,
  body {
    font-size: 18px; } }

@media screen and (max-width: 1023px) and (min-width: 768px) {
  html,
  body {
    font-size: 16px; } }

@media screen and (max-width: 767px) and (min-width: 320px) {
  html,
  body {
    font-size: 15px; }
  .navbarResposnive {
    display: block !important;
    position: fixed;
    -webkit-transform: translate3d(-110%, 0, 0);
    transform: translate3d(-110%, 0, 0); }
  .navbarResposniveOpen {
    display: block !important;
    cursor: pointer;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
    left: 0;
    top: 0;
    position: fixed;
    height: 100%;
    z-index: 244; }
    .navbarResposniveOpen .nav-item {
      list-style: none; }
    .navbarResposniveOpen .nav-link {
      color: #222222; }
  .sadrzaj {
    height: 100%;
    -webkit-transform: translate3d(-110%, 0, 0);
    transform: translate3d(-110%, 0, 0);
    -webkit-transition-duration: 1s;
            transition-duration: 1s;
    width: 300px; }
  .sadrzajOpen {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transition-duration: 1s;
            transition-duration: 1s;
    height: 100%;
    width: 60%; }
    .sadrzajOpen .content {
      background: white;
      height: 100%; }
      .sadrzajOpen .content .isvg svg {
        width: 24px;
        height: 24px;
        color: black; }
      .sadrzajOpen .content .user-area {
        background: #222222;
        height: 160px;
        margin-bottom: 30px; }
        .sadrzajOpen .content .user-area .account .nav-link {
          color: white;
          display: inline;
          padding: 0px !important;
          font-size: 16px; }
        .sadrzajOpen .content .user-area .account p {
          display: unset;
          position: absolute;
          top: 100px;
          left: 15px;
          font-size: 20px;
          color: white;
          width: 200px; }
        .sadrzajOpen .content .user-area .account .isvg {
          top: 30px;
          background: rgba(255, 255, 255, 0.6);
          padding: 20px;
          position: relative;
          border-radius: 100%; } }

@media screen and (max-width: 991px) and (min-width: 768px) {
  .navbarResposnive {
    display: block !important;
    position: fixed;
    -webkit-transform: translate3d(-110%, 0, 0);
    transform: translate3d(-110%, 0, 0); }
  .navbarResposniveOpen {
    display: block !important;
    cursor: pointer;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
    left: 0;
    top: 0;
    position: fixed;
    height: 100%;
    z-index: 244; }
    .navbarResposniveOpen .nav-item {
      list-style: none; }
    .navbarResposniveOpen .nav-link {
      color: #222222; }
  .sadrzaj {
    height: 100%;
    -webkit-transform: translate3d(-110%, 0, 0);
    transform: translate3d(-110%, 0, 0);
    -webkit-transition-duration: 1s;
            transition-duration: 1s;
    width: 300px; }
  .sadrzajOpen {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transition-duration: 1s;
            transition-duration: 1s;
    height: 100%;
    width: 60%; }
    .sadrzajOpen .content {
      background: white;
      height: 100%; }
      .sadrzajOpen .content .isvg svg {
        width: 24px;
        height: 24px;
        color: black; }
      .sadrzajOpen .content .user-area {
        background: #222222;
        height: 160px;
        margin-bottom: 30px; }
        .sadrzajOpen .content .user-area .account .nav-link {
          color: white;
          display: inline;
          padding: 0px !important;
          font-size: 16px; }
        .sadrzajOpen .content .user-area .account p {
          display: unset;
          position: absolute;
          top: 100px;
          left: 15px;
          font-size: 20px;
          color: white;
          width: 200px; }
        .sadrzajOpen .content .user-area .account .isvg {
          top: 30px;
          background: rgba(255, 255, 255, 0.6);
          padding: 20px;
          position: relative;
          border-radius: 100%; } }

.no-pa {
  padding: 0 !important; }

.no-ma {
  margin: 0 !important; }

.no-pa-left {
  padding-left: 0 !important; }

.font-weight-medium {
  font-weight: 500 !important; }

.required {
  border-bottom: 2px solid #E33D3D !important;
  width: 100%;
  padding: 0.375rem 0.375rem; }

.requiredCheckbox {
  border: 2px solid #E33D3D !important; }

.breadcrumbs {
  margin-top: 2rem; }
  .breadcrumbs h3 {
    font-size: 1.1rem;
    line-height: 1.26rem;
    color: #222222; }
    .breadcrumbs h3 span {
      color: #E33D3D; }
    .breadcrumbs h3 a {
      color: #222222;
      text-decoration: none; }
  .breadcrumbs h1 {
    margin-top: 1.65rem;
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: 700;
    font-family: "Merriweather", serif; }
    .breadcrumbs h1::after {
      content: '';
      background-color: #222222;
      height: 1px;
      width: 8rem;
      display: block;
      margin-bottom: 3.5rem; }
  .breadcrumbs h2 {
    margin-top: 1.6rem;
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: 700;
    font-family: "Merriweather", serif; }
    .breadcrumbs h2::after {
      content: '';
      background-color: #222222;
      height: 1px;
      width: 8rem;
      display: block;
      margin-bottom: 3.5rem; }

.unibutton {
  background-color: #E33D3D;
  color: #fff; }
  .unibutton:hover {
    color: #E33D3D;
    background-color: #fff; }

.input-wrap {
  flex-direction: column-reverse; }
  .input-wrap span {
    font-size: 0.8rem; }
    .input-wrap span a {
      color: #E33D3D !important;
      font-weight: 600; }
  .input-wrap .checkbox {
    border: 1px solid #707070;
    width: 15px;
    height: 15px;
    cursor: pointer;
    margin-right: 8px;
    border-radius: 4px;
    float: left;
    margin-top: 10px;
    font-size: 0.8rem; }
  .input-wrap .checked {
    display: flex;
    align-items: center;
    justify-content: center; }
    .input-wrap .checked:after {
      content: '';
      display: block;
      border-right: 3px solid #E33D3D;
      border-bottom: 3px solid #E33D3D;
      width: 5px;
      height: 9px;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg); }

input:focus,
textarea:focus {
  border-color: inherit !important;
  box-shadow: none !important;
  outline: none !important; }

@media screen and (min-width: 991px) {
  .navbarResposniveOpen,
  .navbarResposnive {
    display: none; } }

@media screen and (max-width: 767px) and (min-width: 100px) {
  .header .desktopNavbar {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

.btn-menu {
  display: block !important;
  background: transparent;
  border: 0;
  display: block !important;
  -webkit-transform: scale(2);
          transform: scale(2); }

.mobileLogo svg {
  width: 100%; }

.alert {
  margin-top: 32px; }

.pop-up-messages {
  position: fixed;
  right: 32px;
  top: 275px;
  z-index: 999999;
  width: 400px; }
  @media only screen and (max-width: 768px) {
    .pop-up-messages {
      width: 90%;
      margin-left: 5%;
      top: 0;
      margin-top: 50px;
      left: 0;
      right: unset; } }
  .pop-up-messages > div {
    padding: 42px;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 4px;
    margin-bottom: 16px;
    position: relative;
    cursor: pointer; }
    .pop-up-messages > div .hide {
      color: #FFFFFF;
      position: absolute;
      right: 10px;
      top: 10px;
      font-size: 22px;
      cursor: pointer; }
    .pop-up-messages > div > p {
      color: #FFFFFF;
      display: flex;
      align-items: center;
      margin-bottom: 0; }
      .pop-up-messages > div > p::before {
        content: ' ';
        display: block;
        width: 10px;
        height: 20px;
        border-bottom: 3px solid #67af8a;
        border-right: 3px solid #67af8a;
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
        float: left;
        margin-right: 16px; }
    .pop-up-messages > div .error::before {
      display: none; }
  .pop-up-messages .hide-message {
    -webkit-animation-name: hide-message;
            animation-name: hide-message;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-transform: translate3d(110%, 0, 0);
            transform: translate3d(110%, 0, 0); }

@-webkit-keyframes hide-message {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    -webkit-transform: translate3d(110%, 0, 0);
            transform: translate3d(110%, 0, 0); } }

@keyframes hide-message {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    -webkit-transform: translate3d(110%, 0, 0);
            transform: translate3d(110%, 0, 0); } }
  .pop-up-messages .fa-times {
    color: #DB3838;
    font-size: 24px;
    margin-right: 8px; }

.payment-overview {
  margin-bottom: 30px; }
  .payment-overview h1 {
    font-size: 26px !important; }
  .payment-overview table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 50px;
    font-size: 16px; }
    .payment-overview table tr td {
      padding: 2px 0px; }
      .payment-overview table tr td:nth-child(2) {
        width: 50%; }
    .payment-overview table tr:nth-child(even) {
      background-color: #f2f2f2; }

.discount {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 4rem 4rem 0;
  border-color: transparent #E33D3D transparent transparent;
  position: absolute;
  top: 0;
  right: 0; }
  .discount p {
    position: absolute;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    color: #FFFFFF;
    left: 1.5rem;
    top: 0.5rem;
    font-size: 1rem; }

.relative-wrap {
  position: relative; }

.cart-image {
  height: 110px !important;
  object-fit: contain; }
  @media only screen and (max-width: 768px) {
    .cart-image {
      height: 140px !important; } }

.delivery-note {
  margin-top: 32px;
  font-size: 16px;
  margin-bottom: 0;
  padding-left: 1.4rem; }
  .delivery-note a {
    cursor: pointer;
    color: #E33D3D !important;
    font-weight: 600; }

.payment-footer {
  padding-bottom: 30px; }
  @media only screen and (max-width: 768px) {
    .payment-footer {
      padding-top: 30px; } }
  .payment-footer > div {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    @media only screen and (max-width: 768px) {
      .payment-footer > div {
        flex-direction: column; } }
  .payment-footer .bank-logo {
    justify-content: flex-end;
    margin-bottom: 30px; }
  .payment-footer .sign img {
    width: 90px;
    height: 56px;
    margin-left: 10px; }
  .payment-footer .signs {
    margin-left: 240px; }
    @media only screen and (max-width: 768px) {
      .payment-footer .signs {
        margin-left: 0;
        margin-top: 240px; } }
    .payment-footer .signs img {
      width: 60px;
      margin-left: 10px; }

.cart-icon {
  position: relative;
  width: 30px; }
  .cart-icon .cart-count {
    background-color: #E33D3D;
    border-radius: 50%;
    width: 21px;
    height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    position: absolute;
    font-size: 14px;
    top: -10px;
    right: -5px; }

.cookies {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background-color: #FFFFFF;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px -3px 4px 0px rgba(0, 0, 0, 0.05); }
  .cookies P {
    color: #222222;
    margin-bottom: 0; }
  .cookies button {
    margin: 0 16px;
    border: 0;
    background-color: #222222;
    color: #FFFFFF;
    padding: 6px 16px; }
    .cookies button:hover {
      background-color: #E33D3D; }
  .cookies a {
    color: #222222;
    font-weight: 600;
    font-size: 18px; }
  @media only screen and (max-width: 768px) {
    .cookies {
      flex-direction: column;
      height: unset;
      padding: 32px 16px; }
      .cookies button {
        margin-top: 16px; } }

.active {
  color: #E33D3D !important; }
  .active a {
    color: #E33D3D !important; }

.header {
  background: white; }
  .header .headerTop {
    font-size: 0.7rem;
    color: #222222;
    line-height: 0.85rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-weight: 400;
    height: 2rem;
    border-bottom: 1px solid #646464; }
    .header .headerTop a {
      color: #222222;
      text-decoration: none; }
    .header .headerTop .facebook {
      padding-left: 0.5rem;
      padding-right: 0.5rem; }
    .header .headerTop .phone {
      padding-right: 0.5rem; }
    .header .headerTop .heart {
      padding-left: 1.5rem;
      padding-right: 1.2rem; }
    .header .headerTop .loginHeder {
      float: right;
      display: flex; }
  .header .desktopNavbar {
    display: flex;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #646464; }
    .header .desktopNavbar .navbar-brand {
      margin: auto !important;
      padding: 0 !important; }
    .header .desktopNavbar .navbar {
      padding: 0px !important; }
      .header .desktopNavbar .navbar .nav-link {
        font-size: 0.8rem;
        font-weight: 500;
        color: #222222; }
    .header .desktopNavbar .lijevi .nav-item {
      padding-right: 1.6rem !important; }
      .header .desktopNavbar .lijevi .nav-item:last-child {
        padding-right: 0rem !important; }
      .header .desktopNavbar .lijevi .nav-item .nav-link {
        padding: 0; }
    .header .desktopNavbar .desni .nav-item {
      padding-left: 3.1rem !important; }
      .header .desktopNavbar .desni .nav-item:first-child {
        padding-left: 0rem !important; }
      .header .desktopNavbar .desni .nav-item .nav-link {
        padding: 0 !important; }
  .header .categoryMenu {
    font-family: "Merriweather", serif;
    padding-top: 1.1rem;
    padding-bottom: 0.75rem; }
    .header .categoryMenu .nav .nav-item .nav-link {
      padding: 0;
      font-size: 1rem;
      line-height: 1.25rem;
      color: #222222;
      font-weight: 400;
      text-transform: uppercase; }

@media screen and (max-width: 991px) and (min-width: 768px) {
  .header .headerTop .heart {
    padding-left: 1.2rem; } }

.carousel .search {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 730px;
  top: 286px;
  z-index: 2; }
  .carousel .search input {
    width: 100%;
    border-radius: 30px;
    border: 0;
    outline: none;
    height: 57px;
    padding-left: 25px;
    padding-right: 60px;
    position: relative; }
    .carousel .search input::-webkit-input-placeholder {
      font-size: 18px;
      color: #646464;
      font-weight: 400; }
    .carousel .search input::-moz-placeholder {
      font-size: 18px;
      color: #646464;
      font-weight: 400; }
    .carousel .search input::-ms-input-placeholder {
      font-size: 18px;
      color: #646464;
      font-weight: 400; }
    .carousel .search input::placeholder {
      font-size: 18px;
      color: #646464;
      font-weight: 400; }
  .carousel .search .no-bottom-radius {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px; }
  .carousel .search svg {
    position: absolute;
    top: 18px;
    right: 23px; }

.carousel .carousel-item .overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  background-color: rgba(0, 0, 0, 0.2); }

.carousel .carousel-item img {
  width: 100% !important;
  height: 31.5rem;
  object-fit: cover;
  min-height: 180px; }

.carousel .carousel-indicators {
  margin-bottom: 1.5rem;
  z-index: 3;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel .carousel-indicators .active {
    background: #E33D3D;
    z-index: 3; }
  .carousel .carousel-indicators li {
    border-radius: 100%;
    background: white;
    opacity: 1;
    height: 18px;
    width: 18px;
    border: 0;
    margin: 0 0.5rem;
    z-index: 3; }

.carousel .hide-indicators {
  display: none !important; }

@media screen and (max-width: 991px) and (min-width: 768px) {
  .carousel .search {
    top: 200px;
    width: 400px; }
    .carousel .search input {
      height: 50px; }
    .carousel .search svg {
      top: 15px; } }

@media screen and (max-width: 767px) and (min-width: 300px) {
  .carousel .carousel-indicators {
    display: none; }
  .carousel .search {
    top: 80px;
    width: 280px; }
    .carousel .search input {
      height: 40px; }
      .carousel .search input::-webkit-input-placeholder {
        font-size: 15px; }
      .carousel .search input::-moz-placeholder {
        font-size: 15px; }
      .carousel .search input::-ms-input-placeholder {
        font-size: 15px; }
      .carousel .search input::placeholder {
        font-size: 15px; }
    .carousel .search svg {
      top: 10px; }
  .carousel .carousel-item img {
    height: 200px; } }

.breadcrumbs h1::after {
  all: unset; }

.left h6 {
  color: #E33D3D;
  font-size: 1rem;
  line-height: 1.2rem;
  margin-bottom: 26px; }

.left p {
  margin-top: 1.8rem;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 2rem; }

.right h3 {
  margin-top: -1rem;
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: 700;
  margin-left: 15px;
  margin-bottom: 0; }
  .right h3::after {
    content: '';
    background-color: #222222;
    height: 1px;
    width: 8rem;
    display: block;
    margin-top: 0.25rem;
    margin-bottom: 1.2rem; }

.detailFooter {
  position: relative;
  min-height: 30rem;
  color: #FFFFFF; }
  .detailFooter h1 {
    font-size: 1.8rem;
    font-family: "Merriweather", serif;
    line-height: 2.25rem; }
    .detailFooter h1::after {
      content: '';
      display: block;
      background: #FFFFFF;
      height: 0.025rem;
      width: 8rem;
      margin-top: 1.25rem;
      margin-bottom: 2.175rem; }
  .detailFooter h4 {
    font-size: 1rem;
    line-height: 1.2rem;
    padding-bottom: 1.25rem; }
  .detailFooter .contact {
    background-image: url(/static/media/bgcContact.2c80306d.png);
    background-repeat: no-repeat;
    width: 50%;
    height: 30rem;
    background-size: cover;
    float: left;
    position: relative; }
    .detailFooter .contact .overlay {
      right: 0;
      top: 0;
      bottom: 0;
      position: absolute;
      background: #333333;
      opacity: 0.86;
      height: 100%;
      min-width: 67%; }
      .detailFooter .contact .overlay .content {
        padding: 5.5rem 0.5rem 0 4rem; }
        .detailFooter .contact .overlay .content .btn {
          margin-top: 2.8rem;
          background-color: transparent;
          border: 1px solid #FFFFFF;
          height: 2.3rem;
          width: 11.35rem;
          border-radius: 0; }
          .detailFooter .contact .overlay .content .btn:focus {
            background-color: transparent !important;
            outline: none !important;
            color: #FFFFFF;
            outline-style: none !important;
            -webkit-box-shadow: none !important; }
  .detailFooter .stores {
    background-color: #333333;
    min-height: 30rem;
    width: 50%;
    float: right; }
    .detailFooter .stores .content {
      padding: 5.5rem 0 0 5.25rem; }

.footer {
  margin-top: 3rem; }
  .footer .container {
    border-bottom: 0.025rem solid #222222; }
    .footer .container h4 {
      padding-top: 2.4rem;
      font-size: 0.9rem;
      font-weight: 400;
      padding-bottom: 1.6rem; }
    .footer .container h6 {
      font-size: 1.1rem;
      font-weight: 400;
      padding-bottom: 1.3rem; }
      .footer .container h6 svg {
        margin-left: 1rem; }
    .footer .container h5 {
      margin-top: 0.8rem;
      font-size: 1rem;
      line-height: 1.2rem;
      font-weight: 600; }
      .footer .container h5::after {
        content: '';
        display: block;
        background: #222222;
        width: 6rem;
        height: 0.025rem;
        margin-top: 0.35rem;
        margin-bottom: 2rem; }
    .footer .container .nav-item {
      margin-bottom: 0.9rem;
      list-style: none; }
      .footer .container .nav-item a {
        font-weight: 400;
        text-decoration: none;
        color: #222222; }
  .footer .copyright {
    margin-top: 1.5rem; }
    .footer .copyright .container {
      border-bottom: 0; }
      .footer .copyright .container p {
        font-size: 0.8rem; }
        .footer .copyright .container p a {
          text-decoration: none;
          color: #222222;
          font-weight: 600; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .detailFooter .contact {
    width: 100%;
    float: unset; }
    .detailFooter .contact .overlay {
      width: 100%;
      left: 0; }
  .detailFooter .stores {
    width: 100%;
    float: unset; }
  .footer {
    text-align: center; }
    .footer h5 {
      text-align: start; }
    .footer .nav-item {
      text-align: start; } }

.bg-white {
  margin-top: 2rem;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2); }
  .bg-white h2 {
    margin: 0 0 0.2rem 0;
    font-size: 1rem;
    line-height: 1.4rem;
    font-family: "Merriweather", serif;
    padding: 1.25rem 0 0 1rem;
    text-transform: uppercase; }
    .bg-white h2 .collapseButton {
      background-color: transparent;
      border: 0;
      float: right;
      font-size: 1.6rem;
      font-weight: 600;
      line-height: 1.5rem;
      margin-right: 0.25rem;
      outline: none; }
  .bg-white ul {
    padding-left: 2rem; }
  .bg-white li {
    list-style: none;
    padding-top: 0.8rem;
    font-size: 1rem;
    line-height: 1.2rem;
    color: #4B4A48;
    outline: none; }
    .bg-white li input {
      top: 4px;
      outline: none;
      -webkit-appearance: none;
      background-color: #ffffff;
      border: 2px solid #CCCCCC;
      padding: 0.45rem;
      border-radius: 2.5rem;
      display: inline-block;
      position: relative;
      margin-right: 0.5rem; }
    .bg-white li input:checked:after {
      content: ' ';
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 2.5rem;
      position: absolute;
      top: 0.2rem;
      background: #E33D3D;
      left: 0.2rem;
      font-size: 1.6rem;
      outline: none; }
    .bg-white li .check::after {
      content: ' ';
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 2.5rem;
      position: absolute;
      top: 0.2rem;
      background: #E33D3D;
      left: 0.2rem;
      font-size: 1.6rem;
      outline: none; }
    .bg-white li:checked {
      background-color: #e9ecee;
      color: #99a1a7;
      border: 1px solid #adb8c0; }
  .bg-white .country ul {
    padding-left: 1rem; }
  .bg-white .sort .scroll {
    margin-top: 15px;
    margin-right: 0.75rem;
    height: 9.5rem;
    overflow: auto; }
    .bg-white .sort .scroll::-webkit-scrollbar {
      width: 0.3rem; }
    .bg-white .sort .scroll::-webkit-scrollbar-thumb {
      background: #E33D3D; }
    .bg-white .sort .scroll::-webkit-scrollbar-track {
      background: #F6F6F6; }
  .bg-white .price {
    padding-bottom: 20px; }
    .bg-white .price .scroll {
      margin-top: 15px;
      margin-right: 0.75rem;
      height: 9.5rem;
      overflow: auto; }
      .bg-white .price .scroll::-webkit-scrollbar {
        width: 0.3rem; }
      .bg-white .price .scroll::-webkit-scrollbar-thumb {
        background: #E33D3D; }
      .bg-white .price .scroll::-webkit-scrollbar-track {
        background: #F6F6F6; }

.rightMenu svg {
  width: 2.4rem; }

.rightMenu .root {
  position: relative; }
  .rightMenu .root .slideNav {
    position: fixed !important;
    background-color: white; }
    .rightMenu .root .slideNav .bg-white {
      box-shadow: unset; }
  .rightMenu .root .buttonSlideMenu {
    background-color: transparent;
    border: none;
    outline: none;
    position: absolute;
    right: 1.25rem;
    top: 1rem; }

.padding {
  padding-bottom: 1.25rem;
  padding-top: 1.25rem; }
  .padding .left h4 {
    font-size: 1.6rem;
    font-family: "Merriweather", serif;
    color: #2F3234;
    text-transform: uppercase; }
  .padding .right {
    display: flex; }
    .padding .right p {
      font-size: 1rem;
      line-height: 0.85rem;
      color: #222222;
      margin-top: 0.7rem;
      margin-right: 0.6rem;
      font-weight: 400; }
    .padding .right .form-group {
      min-width: 10rem;
      height: 2rem;
      border-radius: 6px;
      position: relative; }
      .padding .right .form-group select {
        -webkit-appearance: none; }
      .padding .right .form-group svg {
        position: absolute;
        top: 0.9rem;
        right: 0.75rem;
        pointer-events: none; }
      .padding .right .form-group .form-control {
        font-size: 0.9rem; }

.category-search-container {
  width: 380px;
  margin-bottom: 10px; }
  .category-search-container .search {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    z-index: 2; }
    .category-search-container .search input {
      width: 100%;
      border-radius: 6px;
      border: 1px solid #ced4da;
      outline: none;
      height: 52px;
      padding-left: 25px;
      padding-right: 60px;
      position: relative; }
    .category-search-container .search svg {
      position: absolute;
      top: 18px;
      right: 25px; }

.box_category {
  max-width: 255px;
  margin-bottom: 2rem;
  background-color: #FFFFFF;
  font-family: "Merriweather", serif;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  position: relative; }
  .box_category:hover {
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.3); }
  .box_category .img-fluid {
    width: 100%;
    min-height: 326px;
    object-fit: cover; }
  .box_category .action {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 4rem 4rem 0;
    border-color: transparent #E33D3D transparent transparent;
    position: absolute;
    top: 0;
    right: 0; }
    .box_category .action p {
      position: absolute;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
      color: #FFFFFF;
      left: 1.5rem;
      top: 0.5rem;
      font-size: 1rem; }
  .box_category .content {
    text-align: center;
    height: 11.5rem;
    position: relative; }
    .box_category .content a {
      text-decoration: none; }
    .box_category .content h2 {
      padding-top: 0.7rem;
      font-size: 1rem;
      line-height: 1.25rem;
      font-weight: 400;
      text-decoration: none;
      color: #222222; }
    .box_category .content h6 {
      font-size: 0.7rem;
      line-height: 0.9rem;
      font-weight: 300;
      color: #646464;
      margin-bottom: 0.55rem; }
    .box_category .content h3 {
      font-size: 1.2rem;
      line-height: 1.5rem;
      font-weight: 400;
      color: #E33D3D; }
    .box_category .content .btn {
      background-color: transparent;
      color: #E33D3D;
      border: 1px solid #E33D3D;
      border-radius: 0;
      width: 90%;
      height: 2rem;
      padding: 0;
      font-size: 0.8rem;
      font-weight: 400;
      margin-bottom: 0.9rem;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto; }
      .box_category .content .btn:hover {
        background-color: #E33D3D;
        color: #FFFFFF; }
      .box_category .content .btn:focus {
        background-color: #E33D3D !important;
        outline: none !important;
        color: #fff;
        outline-style: none !important;
        -webkit-box-shadow: none !important;
        border: 0; }

.pagination .all .allLink {
  background-color: #E5E5E5;
  border-radius: 6px;
  margin-left: 0.5rem;
  padding: 0.6rem 0.85rem 0.55rem 0.85rem;
  color: #1F1A16;
  font-weight: 700;
  text-decoration: none;
  font-size: 0.8rem; }

.pagination .active .allLink {
  background-color: #DA231B;
  color: #FFFFFF !important;
  text-decoration: none; }

.pagination .break-me a {
  color: black;
  background-color: #E5e5e5;
  border: 5px;
  padding: 0.4rem 0.55rem 0.50rem 0.55rem;
  border-radius: 6px;
  margin-left: 0.5rem;
  text-decoration: none;
  font-size: 1rem; }

.pagination .previous a {
  outline: none;
  background-color: #E5E5E5;
  margin-left: 0.5rem;
  padding: 0.4rem 0.75rem 0.50rem 0.8rem;
  border-radius: 6px; }
  .pagination .previous a::after {
    content: '';
    border: solid #1F1A16;
    border-width: 0 0.1rem 0.1rem 0;
    display: inline-block;
    padding: 0.2rem;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    position: relative; }

.pagination .next a {
  outline: none;
  background-color: #E5E5E5;
  margin-left: 0.5rem;
  padding: 0.4rem 0.75rem 0.50rem 0.8rem;
  border-radius: 6px; }
  .pagination .next a::after {
    content: '';
    border: solid #1F1A16;
    border-width: 0 0.1rem 0.1rem 0;
    display: inline-block;
    padding: 0.2rem;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    position: relative; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .pagination .break-me {
    display: none; } }

@media screen and (max-width: 991px) and (min-width: 768px) {
  .box_category .content {
    height: 14rem; } }

.heading {
  margin-top: 2.2rem; }
  .heading h1 {
    font-size: 1.6rem;
    line-height: 2rem;
    font-family: "Merriweather", serif;
    text-align: center;
    margin: 0; }
    .heading h1::after {
      content: '';
      display: block;
      height: 1px;
      width: 6rem;
      background-color: #646464;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 2.5rem; }
  .heading p {
    margin-bottom: 2rem; }

.deliveryContent {
  position: relative;
  text-align: center; }
  .deliveryContent .divSlika {
    position: relative;
    max-width: 65rem;
    margin-left: auto;
    margin-right: auto; }
    .deliveryContent .divSlika .slika {
      width: 100%;
      height: 24.1rem;
      object-fit: cover; }
    .deliveryContent .divSlika .overlay {
      right: 0;
      top: 0;
      bottom: 0;
      position: absolute;
      background: #333333;
      opacity: 0.25;
      height: 100%;
      width: 100%; }

.proba {
  margin-top: -73px; }
  .proba .box {
    z-index: 1;
    background-color: #FFFFFF !important;
    width: 100%;
    height: 20.5rem;
    text-align: center;
    margin-bottom: 6.5rem; }
    .proba .box .content {
      padding-top: 0.25rem;
      padding: 1.5rem; }
      .proba .box .content h4 {
        padding-top: 0.75rem;
        font-size: 1rem;
        line-height: 1.5rem;
        font-family: "Merriweather", serif;
        font-weight: 700;
        padding-bottom: 1rem;
        margin: 0; }
      .proba .box .content p {
        font-size: 0.9rem;
        line-height: 1.1rem;
        margin: 0; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .proba .box {
    margin-top: 1rem;
    margin-bottom: 1rem; } }

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .proba .box {
    min-height: 350px; } }

.textAbout {
  margin-top: 4.5rem;
  margin-bottom: 3.5rem; }

.experience {
  background-image: url(/static/media/background.49e8b259.png);
  width: 100%; }
  .experience .heading h1 {
    padding-top: 4rem; }
  .experience .box_wrapper .box {
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
    padding: 1.5rem 2.5rem 0 2.5rem;
    text-align: center;
    background-color: #FFFFFF;
    width: 100%;
    min-height: 250px;
    margin-bottom: 4.3rem; }
    .experience .box_wrapper .box h2 {
      font-family: "Merriweather", serif;
      font-weight: 700;
      line-height: 1rem;
      font-size: 1rem;
      margin-top: 0.75rem; }
    .experience .box_wrapper .box p {
      font-size: 0.9rem;
      line-height: 1rem; }

.googleMap .map {
  height: 600px; }

.news_wrapper {
  margin-top: 4.35rem;
  background-image: url(/static/media/background.49e8b259.png);
  background-repeat: no-repeat;
  background-size: cover; }
  .news_wrapper h2 {
    text-align: center;
    padding-top: 3.45rem;
    font-size: 1.6rem;
    line-height: 2rem;
    font-family: "Merriweather", serif; }
    .news_wrapper h2::after {
      content: '';
      width: 8rem;
      height: 1px;
      background-color: #222222;
      display: block;
      position: relative;
      margin-left: auto;
      margin-right: auto;
      margin-top: 0.7rem;
      margin-bottom: 1.8rem; }
  .news_wrapper a {
    text-decoration: none;
    position: relative;
    width: 8.5rem;
    height: 2rem;
    margin-top: 0.75rem;
    margin-bottom: 3rem; }
    .news_wrapper a .btn {
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: #E33D3D;
      padding: 0;
      border: 0;
      border-radius: 0; }
      .news_wrapper a .btn:hover {
        color: #E33D3D;
        background-color: white;
        border: 1px solid #E33D3D; }
      .news_wrapper a .btn:focus {
        background-color: #E33D3D !important;
        outline: none !important;
        color: #fff;
        outline-style: none !important;
        -webkit-box-shadow: none !important;
        border: 0; }

.searchForm {
  background: white;
  position: absolute;
  top: 56px;
  width: 100%;
  padding: 10px;
  box-shadow: 2px 8px 8px 0 rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  z-index: 9999; }
  .searchForm a {
    text-decoration: none;
    margin-left: 5px;
    margin-right: 5px;
    width: 100%; }
    .searchForm a:hover {
      background: #F5F5F5; }
  .searchForm .searchProduct {
    display: flex;
    width: 100%;
    padding: 10px;
    z-index: 9999; }
    .searchForm .searchProduct ul {
      list-style: none;
      width: 100%;
      padding: 0px;
      margin: 0; }
      .searchForm .searchProduct ul li {
        display: flex;
        align-items: center;
        padding: 6px 0; }
      .searchForm .searchProduct ul img {
        height: 42px; }
  .searchForm p {
    color: #222222;
    font-size: 16px;
    margin-bottom: 0; }

.profileContent {
  margin-bottom: 2rem;
  margin-top: 2rem; }
  .profileContent .profileImage .slika {
    height: 4rem;
    width: 4rem;
    border-radius: 100%;
    border: 1px solid #646464; }
  .profileContent .char-image {
    background-color: #222222;
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center; }
    .profileContent .char-image span {
      color: #FFFFFF;
      font-size: 34px;
      font-weight: 400; }
  .profileContent .name h1 {
    font-size: 1.5rem;
    font-family: "Merriweather", serif;
    line-height: 1.9rem;
    margin: 0; }
  .profileContent .name a {
    color: #222222;
    font-size: 1.1rem;
    line-height: 1.3rem;
    cursor: pointer; }
    .profileContent .name a:hover {
      text-decoration: none;
      color: #222222; }

.profileBox {
  margin-bottom: 3rem;
  background-color: #FFFFFF;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  width: 100%; }
  .profileBox .content h1 {
    padding: 1.5rem 0 1rem 1.5rem;
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 1.5rem;
    margin: 0; }
  .profileBox .content li {
    padding: 0.6rem 0 0.6rem 1.5rem;
    display: block;
    list-style: none;
    font-size: 1rem;
    line-height: 1.2rem;
    color: #222222;
    cursor: pointer; }
    .profileBox .content li svg {
      fill: #E33D3D;
      margin-right: 0.6rem;
      margin-bottom: 0.3rem; }
  .profileBox .content .activ {
    background-color: #E33D3D;
    color: #FFFFFF;
    width: 100%; }
    .profileBox .content .activ svg {
      fill: #FFFFFF; }

.personalData {
  margin-bottom: 6.5rem; }
  .personalData .newAddress h1 {
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 1.5rem;
    padding-bottom: 1.5rem;
    padding-top: 1rem; }
  .personalData .personalBox {
    background-color: #FFFFFF;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
    padding: 1.5rem; }
    .personalData .personalBox h1 {
      font-size: 1.3rem;
      font-weight: 600;
      line-height: 1.5rem;
      padding-bottom: 2rem; }
    .personalData .personalBox input {
      padding: .375rem 0 .375rem 0 !important;
      border: 0;
      border-radius: 0;
      border-bottom: 1px solid #646464; }
      .personalData .personalBox input::-webkit-input-placeholder {
        color: #B5B5B5; }
      .personalData .personalBox input::-moz-placeholder {
        color: #B5B5B5; }
      .personalData .personalBox input::-ms-input-placeholder {
        color: #B5B5B5; }
      .personalData .personalBox input::placeholder {
        color: #B5B5B5; }
    .personalData .personalBox .btn {
      margin-top: 2rem;
      margin-left: 15px;
      font-size: 1rem;
      line-height: 1.25rem;
      background-color: #E33D3D;
      color: #FFFFFF;
      height: 2.2rem;
      width: 8rem;
      padding: 0;
      border-radius: 0;
      border: 0;
      font-family: "Merriweather", serif; }
      .personalData .personalBox .btn:hover {
        background-color: #FFFFFF;
        border: 1px solid #E33D3D;
        color: #E33D3D; }
    .personalData .personalBox .btn2 {
      background-color: transparent;
      border: 1px solid #E33D3D;
      color: #E33D3D; }
      .personalData .personalBox .btn2:hover {
        background-color: #E33D3D;
        color: #FFFFFF; }
    .personalData .personalBox .bottom-border {
      border-bottom: 1px solid black; }
      .personalData .personalBox .bottom-border p {
        font-size: 1rem;
        line-height: 1.2rem; }
        .personalData .personalBox .bottom-border p span {
          font-weight: 600; }
    .personalData .personalBox .datas {
      padding-top: 1rem; }
    .personalData .personalBox .favoriteBox {
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
      text-align: center;
      font-family: "Merriweather", serif;
      margin-bottom: 1.5rem; }
      .personalData .personalBox .favoriteBox img {
        width: 100%; }
      .personalData .personalBox .favoriteBox .content {
        padding: 0 0.5rem 0 0.5rem; }
        .personalData .personalBox .favoriteBox .content a {
          text-decoration: none; }
        .personalData .personalBox .favoriteBox .content h3 {
          padding: 0.5rem 0.5rem 0 0.5rem;
          font-size: 0.95rem;
          line-height: 1rem;
          color: #222222; }
        .personalData .personalBox .favoriteBox .content h4 {
          font-size: 0.8rem;
          line-height: 1rem;
          color: #E33D3D; }
        .personalData .personalBox .favoriteBox .content a .btn {
          margin: 0.35rem 0 0 0;
          background-color: transparent;
          color: #E33D3D;
          border-radius: 0;
          border: 1px solid #E33D3D;
          height: 1.5rem;
          font-size: 0.7rem;
          padding: 0;
          margin-bottom: 0.5rem;
          width: 100%; }
          .personalData .personalBox .favoriteBox .content a .btn:hover {
            background-color: #E33D3D;
            color: #FFFFFF; }

.addressTitl {
  margin-bottom: 2rem; }
  .addressTitl h5 {
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.3rem;
    margin: 0; }

.boxAddress {
  background-color: #FFFFFF;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  padding: 1.5rem;
  margin-bottom: 1.5rem; }
  .boxAddress li {
    display: block;
    list-style: none;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #222222;
    word-break: break-all; }
  .boxAddress .btn {
    margin-top: 2rem;
    font-family: "Merriweather", serif;
    background-color: #E33D3D;
    color: #FFFFFF;
    border-radius: 0;
    border: 0;
    height: 2.2rem;
    width: 100%; }
    .boxAddress .btn:hover {
      background-color: #FFFFFF;
      color: #E33D3D;
      border: 1px solid #E33D3D; }

.contactPage {
  text-align: center; }
  .contactPage h1 {
    font-size: 1.6rem;
    line-height: 2rem;
    font-family: "Merriweather", serif;
    font-weight: 700;
    padding-top: 2.1rem; }
    .contactPage h1::after {
      content: '';
      display: block;
      background-color: #222222;
      height: 1px;
      width: 6.3rem;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 2.5rem;
      margin-top: 0.25rem; }
  .contactPage p {
    font-size: 1rem;
    line-height: 1.5rem;
    padding-bottom: 3.5rem; }
  .contactPage .whiteBox {
    margin-bottom: 5rem;
    background-color: white;
    width: 100%;
    height: 12.5rem;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16); }
    .contactPage .whiteBox .content {
      padding-top: 2.6rem; }
      .contactPage .whiteBox .content h4 {
        font-size: 1.6rem;
        line-height: 2rem;
        font-family: "Merriweather", serif; }
        .contactPage .whiteBox .content h4::after {
          content: '';
          background-color: #646464;
          height: 1px;
          display: block;
          width: 5rem;
          margin-top: 0.5rem;
          margin-bottom: 2rem;
          margin-left: auto;
          margin-right: auto; }
      .contactPage .whiteBox .content p {
        font-size: 1rem;
        line-height: 1.2rem;
        padding-bottom: 0.5rem;
        margin: 0;
        display: block; }

.form {
  background-image: url(/static/media/background.49e8b259.png);
  width: 100%;
  text-align: center; }
  .form h1 {
    padding-top: 4rem;
    font-size: 1.6rem;
    line-height: 2rem;
    font-family: "Merriweather", serif; }
    .form h1::after {
      content: '';
      display: block;
      background-color: #222222;
      height: 1px;
      width: 6.3rem;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 4.25rem;
      margin-top: 0.25rem; }
  .form input {
    width: 100%;
    height: 3.25rem;
    border: 0;
    border-radius: 0;
    font-size: 1rem;
    margin-bottom: 2rem; }
    .form input::-webkit-input-placeholder {
      font-size: 1rem; }
    .form input::-moz-placeholder {
      font-size: 1rem; }
    .form input::-ms-input-placeholder {
      font-size: 1rem; }
    .form input::placeholder {
      font-size: 1rem; }
  .form textarea {
    width: 100%;
    height: 17.5rem !important;
    border: 0;
    border-radius: 0;
    font-size: 1rem; }
    .form textarea::-webkit-input-placeholder {
      font-size: 1rem; }
    .form textarea::-moz-placeholder {
      font-size: 1rem; }
    .form textarea::-ms-input-placeholder {
      font-size: 1rem; }
    .form textarea::placeholder {
      font-size: 1rem; }
  .form .simpleButton {
    background: #E33D3D;
    border: 0;
    outline: none;
    color: #FFFFFF;
    z-index: 2;
    font-size: 1.5rem;
    line-height: 1.9rem;
    font-family: "Merriweather", serif;
    width: 17.5rem;
    height: 3rem;
    margin-bottom: 6rem;
    margin-top: 1rem; }
    .form .simpleButton:hover {
      background: #FFFFFF;
      color: #E33D3D; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .contactPage .whiteBox {
    margin-bottom: 3rem; } }

.form .alert {
  margin-bottom: 6rem;
  margin-top: 1rem; }

.newsBox h1 {
  font-family: "Merriweather", serif;
  font-size: 1.6rem;
  line-height: 2rem;
  padding-top: 0.5rem;
  font-weight: 700; }

.newsBox h6 {
  color: #E33D3D;
  font-size: 1rem;
  line-height: 1.2rem; }

.newsBox p {
  font-size: 1rem;
  line-height: 1.2rem;
  height: 7.2rem;
  overflow: hidden; }

.newsBox .image_box {
  position: relative; }
  .newsBox .image_box img {
    width: 100%;
    height: 350px;
    object-fit: cover; }
  .newsBox .image_box .content {
    text-align: center;
    position: absolute;
    top: 0;
    right: 0;
    background-color: #E33D3D;
    color: #FFFFFF;
    width: 4rem;
    height: 4.5rem; }
    .newsBox .image_box .content h4 {
      font-size: 2.1rem;
      font-weight: 400;
      margin: 0; }
    .newsBox .image_box .content h3 {
      font-size: 1.3rem;
      font-weight: 400; }

.whitebox {
  background-color: #fff;
  margin-bottom: 3.5rem; }
  .whitebox h6 {
    margin-bottom: 0.8rem; }
  .whitebox p {
    line-height: 1.5rem;
    max-height: 7.5rem;
    overflow: hidden;
    padding-right: 0.5rem; }
  .whitebox .white .head {
    padding-top: 0.85rem; }
    .whitebox .white .head .btndetail {
      background-color: transparent;
      color: #E33D3D;
      border-radius: 0;
      border: 1px solid #E33D3D;
      height: 2.5rem;
      width: 8rem;
      outline: none;
      margin-bottom: 0.5rem; }
      .whitebox .white .head .btndetail:hover {
        background-color: #E33D3D;
        color: #FFFFFF;
        border: 0; }
      .whitebox .white .head .btndetail:focus {
        background-color: #E33D3D !important;
        outline: none !important;
        color: #fff;
        outline-style: none !important;
        -webkit-box-shadow: none !important;
        border: 0; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .whitebox .white .head {
    padding: 15px; } }

.newsDetail {
  padding-bottom: 3rem; }

.recommendations h1 {
  margin-top: 2rem;
  text-align: center;
  font-size: 1.6rem;
  line-height: 2rem;
  margin-bottom: 0; }
  .recommendations h1::after {
    content: '';
    background-color: #222222;
    height: 1px;
    width: 6.3rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0.25rem;
    margin-bottom: 2.5rem; }

.recommendations p {
  margin-bottom: 2.6rem; }

.recommendation {
  margin-bottom: 2rem;
  background-color: #fff;
  padding: 1.5rem; }
  .recommendation .content {
    border-bottom: 1px solid #646464;
    margin-bottom: 0.75rem; }
    .recommendation .content .no-pr {
      padding-right: 0; }
    .recommendation .content h1 {
      font-family: "Merriweather", serif;
      font-size: 1.6rem;
      line-height: 2rem;
      margin-bottom: 0.95rem; }
    .recommendation .content h4 {
      padding-bottom: 2rem;
      color: #E33D3D;
      font-size: 1.6rem;
      line-height: 2rem;
      font-family: "Merriweather", serif; }
    .recommendation .content p {
      margin-bottom: 0.65rem;
      line-height: 1.2rem; }
      .recommendation .content p span {
        font-weight: 700; }
    .recommendation .content .btn {
      background-color: transparent;
      color: #E33D3D;
      border: 1px solid #E33D3D;
      height: 2rem;
      width: 8rem;
      padding: 0;
      border-radius: 0;
      font-family: "Merriweather", serif;
      font-size: 1rem;
      line-height: 1.25rem; }
      .recommendation .content .btn:hover {
        color: #FFFFFF;
        background-color: #E33D3D; }
      .recommendation .content .btn:focus {
        background-color: #E33D3D !important;
        outline: none !important;
        color: #fff;
        outline-style: none !important;
        -webkit-box-shadow: none !important;
        border: 0; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .recommendation .content .no-pr {
    padding-right: 15px; }
  .recommendation .content img {
    width: 100%;
    padding-bottom: 1rem; } }

.detailWrapper .breadcrumbs {
  padding: 2rem 0 0.85rem 0; }

.detailWrapper .detaill {
  color: #222222;
  background-color: #FFFFFF;
  padding: 1.5rem; }
  .detailWrapper .detaill .smallImg {
    padding-bottom: 1.3rem;
    cursor: pointer; }
  .detailWrapper .detaill .preview-image {
    width: 100%;
    max-height: 560px;
    object-fit: contain; }
  .detailWrapper .detaill .text h5 {
    font-size: 1rem;
    line-height: 1.2rem;
    font-weight: 400; }
  .detailWrapper .detaill .text h1 {
    margin-top: 0.5rem;
    font-size: 1.5rem;
    line-height: 1.9rem;
    font-family: "Merriweather", serif; }
    .detailWrapper .detaill .text h1::after {
      content: '';
      display: block;
      height: 1px;
      background-color: #222222;
      width: 100%;
      margin-top: 0.25rem; }
  .detailWrapper .detaill .text .status {
    margin: 1.3rem 0 1rem 0;
    /*
                .reed {
                    height: 0.9rem;
                    width: 0.9rem;
                    background-color: $red;
                    border-radius: 100%;
                     margin: auto 0.45rem auto 1.35rem;
                }*/ }
    .detailWrapper .detaill .text .status .green {
      height: 0.9rem;
      width: 0.9rem;
      background-color: #87DE8A;
      border-radius: 100%;
      margin: auto 0.45rem auto 1.35rem; }
    .detailWrapper .detaill .text .status .red {
      height: 0.9rem;
      width: 0.9rem;
      background-color: #E33D3D;
      border-radius: 100%;
      margin: auto 0.45rem auto 1.35rem; }
    .detailWrapper .detaill .text .status h2 {
      margin: auto 0 auto 0;
      font-weight: 400;
      font-size: 1.5rem;
      line-height: 1.8rem; }
    .detailWrapper .detaill .text .status h6 {
      margin: auto 0 auto 0;
      font-size: 0.9rem;
      line-height: 1.1rem; }
  .detailWrapper .detaill .text p {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.2rem; }
  .detailWrapper .detaill .text .hr {
    height: 0.05rem;
    background-color: #222222; }
  .detailWrapper .detaill .prise {
    padding-top: 1.15rem; }
    .detailWrapper .detaill .prise h2 {
      font-family: "Merriweather", serif;
      font-weight: 400;
      position: relative;
      font-size: 1.5rem;
      line-height: 1.9rem; }
      .detailWrapper .detaill .prise h2::after {
        content: '';
        width: 7.15rem;
        height: 2px;
        display: block;
        background-color: #222222;
        position: absolute;
        top: 1rem;
        left: -0.3rem; }
    .detailWrapper .detaill .prise h3 {
      color: #E33D3D;
      font-family: "Merriweather", serif;
      font-size: 1.5rem;
      line-height: 1.9rem; }
  .detailWrapper .detaill .inputFields {
    margin-top: 2rem; }
    .detailWrapper .detaill .inputFields .quantity {
      position: relative;
      background-color: white;
      border: 1px solid #646464;
      max-width: 8rem;
      height: 60px; }
      .detailWrapper .detaill .inputFields .quantity label {
        position: absolute;
        left: 1rem;
        top: -0.6rem;
        background-color: #fff;
        font-size: 0.9rem;
        line-height: 1rem; }
      .detailWrapper .detaill .inputFields .quantity h2 {
        font-size: 0.9rem;
        margin: auto; }
      .detailWrapper .detaill .inputFields .quantity .btnn {
        color: #222222;
        background-color: transparent;
        border: none;
        outline: inherit;
        padding: 0;
        font-size: 32px;
        margin: auto; }
    .detailWrapper .detaill .inputFields .form-group {
      position: relative;
      margin: 0; }
      .detailWrapper .detaill .inputFields .form-group label {
        position: absolute;
        z-index: 2;
        top: -0.6rem;
        left: 20px;
        background-color: white;
        font-size: 0.9rem;
        line-height: 1rem; }
      .detailWrapper .detaill .inputFields .form-group .placeholder {
        position: absolute;
        z-index: 2;
        right: 45px;
        top: 16px;
        pointer-events: none; }
      .detailWrapper .detaill .inputFields .form-group .form-control {
        height: 60px;
        max-width: 100%;
        position: relative;
        border-radius: 0;
        border: 1px solid #646464; }
      .detailWrapper .detaill .inputFields .form-group select {
        -webkit-appearance: none; }
        .detailWrapper .detaill .inputFields .form-group select:focus {
          -webkit-box-shadow: none !important; }
      .detailWrapper .detaill .inputFields .form-group svg {
        position: absolute;
        bottom: 25px;
        right: 15px;
        pointer-events: none; }
  .detailWrapper .detaill .favorite {
    cursor: pointer;
    margin-top: 1.25rem; }
    .detailWrapper .detaill .favorite svg {
      margin: auto; }
    .detailWrapper .detaill .favorite h6 {
      margin: auto 0 auto 0.5rem;
      font-weight: 400; }
  .detailWrapper .detaill .button {
    padding-top: 1.25rem; }
    .detailWrapper .detaill .button .btnKorpa {
      width: 100%;
      background-color: transparent;
      border: 1px solid #E33D3D;
      font-size: 1.6rem;
      color: #E33D3D;
      font-family: "Merriweather", serif;
      line-height: 2rem;
      outline: none; }
      .detailWrapper .detaill .button .btnKorpa:hover {
        background-color: #E33D3D;
        color: #FFFFFF; }
  .detailWrapper .detaill .header {
    padding-top: 3.8rem;
    border-bottom: 1px solid #222222; }
    .detailWrapper .detaill .header h4 {
      font-size: 1.25rem;
      color: #222222;
      font-weight: 400;
      margin-right: 2rem;
      cursor: pointer; }
    .detailWrapper .detaill .header .active {
      color: #E33D3D; }
      .detailWrapper .detaill .header .active::after {
        position: relative;
        top: 12px;
        content: '';
        display: block;
        width: 6.2rem;
        height: 4px;
        background-color: #E33D3D; }
  .detailWrapper .detaill .description {
    border-bottom: 1px solid #222222; }
    .detailWrapper .detaill .description .spec {
      padding-bottom: 1rem;
      padding-top: 1.6rem; }
      .detailWrapper .detaill .description .spec p {
        font-size: 1rem;
        line-height: 1.2rem;
        padding-bottom: 0;
        margin-bottom: 0.4rem; }
    .detailWrapper .detaill .description .about {
      padding-top: 1.6rem; }

.upLine {
  border-right: 1px solid #222222; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .detailWrapper .breadcrumbs p {
    font-size: 1rem; }
  .detailWrapper .detaill .text h5 {
    padding-top: 0.8rem; }
  .detailWrapper .detaill .text h1 {
    font-size: 1.3rem; }
  .underline {
    border-bottom: 1px solid #222222;
    margin-bottom: 10px;
    padding-bottom: 10px;
    width: 100%; }
  .upLine {
    border-right: 0; } }

.whiteFooter {
  background-color: #fff; }
  .whiteFooter .footer {
    padding-top: 3rem;
    margin-top: 0; }

.login {
  margin-bottom: 3rem;
  margin-top: 2.15rem; }
  .login .switchContent {
    border-bottom: 1px solid #222222;
    margin-bottom: 1.5rem; }
    .login .switchContent h4 {
      font-size: 1.3rem;
      line-height: 1.65rem;
      font-family: "Merriweather", serif;
      margin-right: 2.5rem;
      margin-bottom: 0.3rem;
      cursor: pointer; }
    .login .switchContent .activ::after {
      position: relative;
      top: 0.5rem;
      content: '';
      display: block;
      width: 100%;
      height: 4px;
      background-color: #E33D3D; }
  .login .loginBox {
    background-color: #FFFFFF;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
    width: 100%; }
    .login .loginBox .content {
      color: #222222;
      padding: 1.5rem 2.5rem 2.25rem 1.5rem;
      text-align: start; }
      .login .loginBox .content input {
        padding: .375rem 0 .375rem 0 !important;
        border: none;
        border-bottom: 1px solid #646464;
        border-radius: 0;
        outline: none; }
        .login .loginBox .content input::-webkit-input-placeholder {
          color: #B5B5B5;
          font-size: 1rem;
          line-height: 1.2rem; }
        .login .loginBox .content input::-moz-placeholder {
          color: #B5B5B5;
          font-size: 1rem;
          line-height: 1.2rem; }
        .login .loginBox .content input::-ms-input-placeholder {
          color: #B5B5B5;
          font-size: 1rem;
          line-height: 1.2rem; }
        .login .loginBox .content input::placeholder {
          color: #B5B5B5;
          font-size: 1rem;
          line-height: 1.2rem; }
      .login .loginBox .content label {
        font-size: 0.8rem;
        line-height: 1rem; }
        .login .loginBox .content label span {
          color: #E33D3D;
          font-weight: 600; }
      .login .loginBox .content h6 {
        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: 400;
        margin-bottom: 2.5rem; }
      .login .loginBox .content .btn {
        margin-top: 2rem;
        color: #E33D3D;
        border: 1px solid #E33D3D;
        border-radius: 0;
        font-size: 1rem;
        line-height: 1.25rem;
        font-family: "Merriweather", serif;
        width: 100%;
        height: 2.5rem;
        background-color: transparent; }
        .login .loginBox .content .btn:hover {
          color: #FFFFFF;
          background-color: #E33D3D; }
      .login .loginBox .content a {
        color: #222222; }
        .login .loginBox .content a p {
          text-decoration: underline;
          font-size: 0.9rem;
          line-height: 1.1rem;
          margin-top: 1.5rem; }

.forgot {
  margin-bottom: 3rem;
  margin-top: 2.1rem; }
  .forgot .title {
    border-bottom: 1px solid black;
    margin-bottom: 1.55rem; }
    .forgot .title h4 {
      text-align: center;
      font-size: 1.3rem;
      line-height: 1.15rem;
      font-family: "Merriweather", serif;
      margin: 0.3rem; }
      .forgot .title h4::after {
        content: '';
        display: block;
        width: 13.4rem;
        height: 4px;
        background-color: #E33D3D;
        position: relative;
        top: 8px;
        margin-right: auto;
        margin-left: auto; }
  .forgot .forgotBox {
    background-color: #FFFFFF;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
    width: 100%; }
    .forgot .forgotBox .content {
      color: #222222;
      padding: 1.5rem 2.5rem 2.25rem 1.5rem;
      text-align: start; }
      .forgot .forgotBox .content h6 {
        font-size: 1rem;
        line-height: 1.2rem;
        padding-bottom: 1.5rem;
        font-weight: 400; }
      .forgot .forgotBox .content p {
        font-size: 1rem;
        line-height: 1.2rem; }
      .forgot .forgotBox .content input {
        padding: .375rem 0 .375rem 0 !important;
        border: 0;
        border-radius: 0;
        border-bottom: 1px solid #646464; }
        .forgot .forgotBox .content input::-webkit-input-placeholder {
          color: #B5B5B5; }
        .forgot .forgotBox .content input::-moz-placeholder {
          color: #B5B5B5; }
        .forgot .forgotBox .content input::-ms-input-placeholder {
          color: #B5B5B5; }
        .forgot .forgotBox .content input::placeholder {
          color: #B5B5B5; }
      .forgot .forgotBox .content .btn {
        margin-top: 1rem;
        width: 100%;
        height: 2.5rem;
        border-radius: 0;
        border: 1px solid #E33D3D;
        color: #E33D3D;
        background-color: transparent;
        font-family: "Merriweather", serif;
        font-size: 1rem;
        line-height: 1.25rem;
        padding: 0; }
        .forgot .forgotBox .content .btn:hover {
          color: #FFFFFF;
          background-color: #E33D3D; }

.headingText {
  padding-bottom: 20px;
  margin-top: 1.7rem; }
  .headingText h1 {
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: 700;
    font-family: "Merriweather", serif; }
  .headingText h6 {
    font-size: 1.3rem;
    line-height: 1.5rem;
    font-weight: 400;
    margin-bottom: 2rem; }

.shopLocations .map {
  height: 24.1rem; }

.contactDetail {
  position: relative; }
  .contactDetail .bgc {
    width: 100%;
    height: 24.1rem;
    object-fit: cover; }
  .contactDetail .overlay {
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    background: #333333;
    opacity: 0.86;
    height: 100%;
    width: 100%; }
    .contactDetail .overlay .content {
      color: #fff;
      padding: 2rem 0 0 5rem; }
      .contactDetail .overlay .content h1 {
        font-family: "Merriweather", serif;
        font-size: 1.6rem;
        line-height: 2rem; }
        .contactDetail .overlay .content h1::after {
          content: '';
          display: block;
          height: 1px;
          background-color: #646464;
          width: 8rem;
          margin-bottom: 2.4rem; }
      .contactDetail .overlay .content p {
        font-size: 1rem;
        line-height: 1.2rem;
        margin-bottom: 1.5rem; }
    .contactDetail .overlay .left {
      padding-left: 20rem; }

.imgSlider {
  margin-bottom: 2rem;
  min-height: 250px;
  width: 100%;
  background-color: #fff; }
  .imgSlider .slick-slider {
    position: relative;
    margin: 0 auto;
    width: 80%; }
    .imgSlider .slick-slider .slick-list {
      background-color: #fff;
      float: left;
      width: 100%;
      overflow: hidden;
      margin-top: 1rem;
      margin-bottom: 0.75rem; }
      .imgSlider .slick-slider .slick-list img {
        max-width: 350px;
        min-height: 350px;
        object-fit: cover; }
    .imgSlider .slick-slider .slick-arrow {
      right: unset;
      background: white;
      color: transparent;
      height: 2.5rem;
      width: 2.5rem;
      z-index: 1;
      outline: none;
      box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16); }
    .imgSlider .slick-slider .slick-prev {
      position: absolute;
      left: -3.2rem;
      top: 5.6rem; }
      .imgSlider .slick-slider .slick-prev::after {
        content: "" !important;
        display: block !important;
        border-left: 2px solid #E33D3D !important;
        border-top: 2px solid #E33D3D !important;
        width: 0.6rem !important;
        height: 0.6rem !important;
        -webkit-transform: rotate(-45deg) !important;
                transform: rotate(-45deg) !important;
        top: 0.15rem;
        position: absolute;
        left: 0;
        margin: 0.75rem;
        margin-left: 0.85rem; }
      .imgSlider .slick-slider .slick-prev:before {
        all: unset; }
    .imgSlider .slick-slider .slick-next {
      position: absolute;
      right: -3.2rem;
      top: 5.6rem; }
      .imgSlider .slick-slider .slick-next::after {
        content: "" !important;
        display: block !important;
        border-left: 2px solid #E33D3D !important;
        border-top: 2px solid #E33D3D !important;
        width: 0.6rem !important;
        height: 0.6rem !important;
        -webkit-transform: rotate(135deg) !important;
                transform: rotate(135deg) !important;
        margin: 0.75rem;
        top: 0.15rem;
        position: absolute;
        left: 0; }
      .imgSlider .slick-slider .slick-next:before {
        all: unset; }
  .imgSlider .slick-slide {
    background-color: #fff;
    float: left;
    padding-left: 15px;
    padding-right: 15px; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .imgSlider .slick-slider .slick-prev {
    display: none !important; }
  .imgSlider .slick-slider .slick-next {
    display: none !important; }
  .imgSlider .slick-slide {
    padding: 0; } }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .contactDetail .overlay .content {
    padding: 2rem 0 0 5rem; }
  .contactDetail .overlay .left {
    padding-left: 5rem; } }

@media screen and (max-width: 1366px) and (min-width: 1024px) {
  .contactDetail .overlay .left {
    padding-left: 5rem; } }

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .contactDetail .overlay .left {
    padding-left: 5rem; } }

.universalSlider {
  padding-top: 3rem;
  background: #F5F5F5; }
  .universalSlider .heading {
    text-align: center; }
    .universalSlider .heading h6 {
      font-size: 1.6rem;
      line-height: 2rem;
      font-weight: 400;
      position: relative;
      padding-top: 0.5rem;
      font-family: "Merriweather", serif; }
      .universalSlider .heading h6:after {
        content: '';
        position: absolute;
        bottom: -5px;
        width: 8rem;
        height: 1px;
        background: #646464;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0; }
    .universalSlider .heading p {
      color: #646464;
      font-size: 1.1rem;
      line-height: 1.3rem;
      font-weight: 300;
      margin-bottom: 0; }
  .universalSlider .test {
    float: left;
    width: 100%; }
    .universalSlider .test .boxWrapper {
      height: 31.95rem;
      position: relative; }
      .universalSlider .test .boxWrapper img {
        width: 100%;
        height: 21.7rem;
        object-fit: cover; }
      .universalSlider .test .boxWrapper .action {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 4rem 4rem 0;
        border-color: transparent #E33D3D transparent transparent;
        position: absolute;
        top: 0;
        right: 0; }
        .universalSlider .test .boxWrapper .action p {
          position: absolute;
          -webkit-transform: rotate(45deg);
                  transform: rotate(45deg);
          color: #FFFFFF;
          left: 1.5rem;
          top: 0.5rem;
          font-size: 1rem; }
      .universalSlider .test .boxWrapper .box {
        height: 10.25rem;
        text-align: center;
        background: white;
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
        font-family: "Merriweather", serif;
        padding-top: 0.8rem;
        padding-bottom: 1rem; }
        .universalSlider .test .boxWrapper .box h6 {
          height: 2.5rem;
          overflow: hidden;
          padding: 0 1rem;
          line-height: 1.25rem;
          font-size: 1rem;
          font-weight: 400;
          margin-bottom: 6px; }
        .universalSlider .test .boxWrapper .box p {
          color: #E33D3D;
          font-size: 1.2rem;
          line-height: 1.55rem;
          margin-bottom: 0.8rem;
          padding-top: 0.5rem;
          font-weight: 400; }
        .universalSlider .test .boxWrapper .box .subtitle {
          color: #646464;
          font-size: 0.7rem;
          line-height: 0.8rem;
          font-weight: 300;
          margin: 0;
          padding-top: 0; }
        .universalSlider .test .boxWrapper .box button {
          background: white;
          border: 0;
          outline: none;
          color: #E33D3D;
          font-size: 0.8rem;
          font-weight: 400;
          z-index: 2;
          line-height: 1rem;
          padding: 0.45rem 3.8rem;
          border: 1px solid #E33D3D;
          height: 2rem;
          width: 90%;
          position: absolute;
          bottom: .9rem;
          left: .7rem;
          right: .7rem; }
          .universalSlider .test .boxWrapper .box button:hover {
            background: #E33D3D;
            color: white; }
    .universalSlider .test .slick-slider {
      padding-top: 0px;
      width: 100%; }
      .universalSlider .test .slick-slider .slick-list {
        float: left;
        width: 100%;
        overflow: hidden;
        padding-top: 1.5rem; }
      .universalSlider .test .slick-slider .slick-prev {
        position: absolute;
        left: 15px;
        top: -3rem;
        right: unset;
        background: white;
        color: transparent;
        height: 36px;
        width: 36px;
        z-index: 1;
        outline: none;
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16); }
        .universalSlider .test .slick-slider .slick-prev::after {
          content: "" !important;
          display: block !important;
          border-left: 2px solid #E33D3D !important;
          border-top: 2px solid #E33D3D !important;
          width: 12px !important;
          height: 12px !important;
          -webkit-transform: rotate(-45deg) !important;
                  transform: rotate(-45deg) !important;
          top: 3px;
          position: absolute;
          left: 0;
          margin: 10px;
          margin-left: 15px; }
        .universalSlider .test .slick-slider .slick-prev:before {
          all: unset; }
      .universalSlider .test .slick-slider .slick-next {
        position: absolute;
        right: 15px;
        top: -3rem;
        background: white;
        color: transparent;
        height: 36px;
        width: 36px;
        z-index: 1;
        outline: none;
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16); }
        .universalSlider .test .slick-slider .slick-next::after {
          content: "" !important;
          display: block !important;
          border-left: 2px solid #E33D3D !important;
          border-top: 2px solid #E33D3D !important;
          width: 12px !important;
          height: 12px !important;
          -webkit-transform: rotate(135deg) !important;
                  transform: rotate(135deg) !important;
          margin: 10px;
          top: 3px;
          position: absolute;
          left: 0; }
        .universalSlider .test .slick-slider .slick-next:before {
          all: unset; }
    .universalSlider .test .slick-slide {
      float: left;
      padding-left: 15px;
      padding-right: 15px;
      margin-bottom: 0.5rem; }
      .universalSlider .test .slick-slide img {
        width: 100%; }

.universalSliderWrapper {
  position: relative;
  margin-top: 3rem;
  padding-bottom: 5.5rem; }
  .universalSliderWrapper .bg {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    top: 0;
    left: 0; }
  .universalSliderWrapper .universalSlider {
    padding-top: 4rem; }

@media screen and (max-width: 767px) and (min-width: 300px) {
  .universalSlider {
    padding-top: 3rem; }
    .universalSlider .heading h6 {
      padding-bottom: 0.5rem; }
    .universalSlider .heading p {
      padding-top: 0.5rem; }
    .universalSlider .nav .nav-item {
      margin-right: 1.3rem; }
      .universalSlider .nav .nav-item::after {
        right: -1rem; }
    .universalSlider .test {
      padding-top: 1rem; }
      .universalSlider .test .boxWrapper .box button {
        font-size: 0.8rem;
        padding: 0.5rem 2.5rem; }
      .universalSlider .test .slick-slider .slick-prev {
        top: -4.9rem;
        right: unset;
        left: 15px; }
      .universalSlider .test .slick-slider .slick-next {
        top: -4.9rem;
        right: 15px;
        left: unset; } }

.articleSlider {
  padding-top: 3.5rem;
  background: #F5F5F5; }
  .articleSlider .nav {
    font-family: "Merriweather", serif; }
    .articleSlider .nav .nav-item {
      position: relative;
      margin-right: 3.8rem; }
      .articleSlider .nav .nav-item:last-child::after {
        all: unset; }
      .articleSlider .nav .nav-item:after {
        content: "|";
        position: absolute;
        top: 0;
        right: -2.2rem;
        line-height: 1.15rem;
        color: #646464; }
      .articleSlider .nav .nav-item a {
        cursor: pointer; }
      .articleSlider .nav .nav-item .nav-link {
        padding: 0;
        position: relative;
        color: #222222;
        font-size: 1rem;
        line-height: 1.15rem; }
      .articleSlider .nav .nav-item .active {
        color: #E33D3D; }
  .articleSlider .slick-slide img {
    width: 100%;
    height: 15.75rem;
    object-fit: cover; }
  .articleSlider .test {
    float: left;
    width: 100%;
    padding-top: 1rem; }
    .articleSlider .test .articleBoxWrapper {
      height: 23.25rem;
      position: relative; }
      .articleSlider .test .articleBoxWrapper .action {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 4rem 4rem 0;
        border-color: transparent #E33D3D transparent transparent;
        position: absolute;
        top: 0;
        right: 0; }
        .articleSlider .test .articleBoxWrapper .action p {
          position: absolute;
          -webkit-transform: rotate(45deg);
                  transform: rotate(45deg);
          color: #FFFFFF;
          left: 1.5rem;
          top: 0.5rem;
          font-size: 1rem; }
      .articleSlider .test .articleBoxWrapper .articleBox {
        height: 7.5rem;
        text-align: center;
        background: white;
        padding: 0.5rem 0;
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
        font-family: "Merriweather", serif;
        position: relative; }
        .articleSlider .test .articleBoxWrapper .articleBox h6 {
          overflow: hidden;
          height: 3rem;
          padding: 0 1.5rem;
          line-height: 1rem;
          font-size: 0.9rem;
          font-weight: 400;
          margin-bottom: 6px; }
        .articleSlider .test .articleBoxWrapper .articleBox p {
          color: #E33D3D;
          font-size: 0.8rem;
          line-height: 1rem;
          margin-bottom: 0.8rem; }
        .articleSlider .test .articleBoxWrapper .articleBox button {
          background: white;
          border: 0;
          outline: none;
          color: #E33D3D;
          font-size: 0.6rem;
          font-weight: 400;
          z-index: 2;
          line-height: 0.75rem;
          bottom: 0.44rem;
          left: .5rem;
          position: absolute;
          border: 1px solid #E33D3D;
          height: 1.3rem;
          width: 90%; }
          .articleSlider .test .articleBoxWrapper .articleBox button:hover {
            background: #E33D3D;
            color: white; }
    .articleSlider .test .slick-slider {
      padding-top: 0px;
      width: 100%; }
      .articleSlider .test .slick-slider .slick-list {
        float: left;
        width: 100%;
        overflow: hidden;
        margin-top: 20px; }
      .articleSlider .test .slick-slider .slick-prev {
        position: absolute;
        right: 60px;
        top: -1rem;
        left: unset;
        background: white;
        color: transparent;
        height: 36px;
        width: 36px;
        z-index: 1;
        outline: none;
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16); }
        .articleSlider .test .slick-slider .slick-prev::after {
          content: "" !important;
          display: block !important;
          border-left: 2px solid #E33D3D !important;
          border-top: 2px solid #E33D3D !important;
          width: 12px !important;
          height: 12px !important;
          -webkit-transform: rotate(-45deg) !important;
                  transform: rotate(-45deg) !important;
          top: 3px;
          position: absolute;
          left: 0;
          margin: 10px;
          margin-left: 15px; }
        .articleSlider .test .slick-slider .slick-prev:before {
          all: unset; }
      .articleSlider .test .slick-slider .slick-next {
        position: absolute;
        right: 15px;
        top: -1rem;
        background: white;
        color: transparent;
        height: 36px;
        width: 36px;
        z-index: 1;
        outline: none;
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16); }
        .articleSlider .test .slick-slider .slick-next::after {
          content: "" !important;
          display: block !important;
          border-left: 2px solid #E33D3D !important;
          border-top: 2px solid #E33D3D !important;
          width: 12px !important;
          height: 12px !important;
          -webkit-transform: rotate(135deg) !important;
                  transform: rotate(135deg) !important;
          margin: 10px;
          top: 3px;
          position: absolute;
          left: 0; }
        .articleSlider .test .slick-slider .slick-next:before {
          all: unset; }
    .articleSlider .test .slick-slide {
      float: left;
      padding-left: 15px;
      padding-right: 15px;
      margin-bottom: 0.5rem; }

@media screen and (max-width: 767px) and (min-width: 300px) {
  .articleSlider {
    padding-top: 1rem; }
    .articleSlider .nav .nav-item {
      margin-right: 1.3rem; }
      .articleSlider .nav .nav-item::after {
        right: -1rem; }
    .articleSlider .test {
      padding-top: 1rem; }
      .articleSlider .test .articleBoxWrapper .articleBox button {
        font-size: 0.8rem; }
      .articleSlider .test .slick-slider .slick-prev {
        top: -1.5rem;
        right: 60px;
        left: unset; }
      .articleSlider .test .slick-slider .slick-next {
        top: -1.5rem;
        right: 15px;
        left: unset; } }

.bannerSection {
  padding-top: 2rem;
  background: #F5F5F5; }
  .bannerSection .bannerBoxWrapper {
    position: relative; }
    .bannerSection .bannerBoxWrapper button {
      background: white;
      border: 0;
      outline: none;
      color: #E33D3D;
      font-size: 0.8rem;
      font-weight: 500;
      z-index: 2;
      position: absolute;
      left: 2rem;
      line-height: 0.9rem;
      bottom: 1.5rem;
      padding: 0.8rem 1.75rem; }

.banner2 {
  padding-top: 3.5rem;
  padding-bottom: 0.5rem; }

@media screen and (max-width: 767px) and (min-width: 300px) {
  .bannerSection .bannerBoxWrapper {
    margin-bottom: 2rem !important; } }

.cartBox {
  background-color: #FFFFFF;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  width: 100%;
  padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  margin-top: 2.5rem;
  margin-bottom: 2rem; }
  .cartBox h1 {
    padding-top: 1rem;
    padding-left: 1.4rem;
    font-size: 1.3rem;
    line-height: 1.55rem;
    color: #222222;
    font-weight: 600; }
    .cartBox h1 span {
      color: #E33D3D; }
  .cartBox h6 {
    font-size: 1rem;
    line-height: 1.2rem;
    font-family: "Merriweather", serif;
    margin: 0; }
    .cartBox h6::after {
      content: '';
      display: block;
      height: 1px;
      width: 5rem;
      background-color: #646464;
      margin-top: 0.25rem;
      margin-bottom: 1rem; }
  .cartBox h5 {
    font-size: 1rem;
    line-height: 1.2rem;
    font-family: "Merriweather", serif;
    margin-top: 2.5rem;
    margin-bottom: 1.5rem; }
    .cartBox h5::after {
      content: '';
      display: block;
      height: 1px;
      width: 9.5rem;
      background-color: #646464;
      margin-top: 0.25rem;
      margin-bottom: 1rem; }
  .cartBox .ukupno-price {
    padding-top: 1rem; }
    .cartBox .ukupno-price .hr {
      background-color: #222222;
      width: 100%;
      height: 1px;
      margin-top: .5rem;
      margin-bottom: .5rem; }
    .cartBox .ukupno-price h4 {
      font-size: 1rem;
      font-weight: 600; }
      .cartBox .ukupno-price h4 span {
        color: #E33D3D; }
    .cartBox .ukupno-price h6 {
      font-size: 1rem;
      line-height: 1.2rem; }
    .cartBox .ukupno-price h5 {
      font-size: 1.3rem;
      line-height: 1.5rem;
      font-weight: 600; }
      .cartBox .ukupno-price h5 span {
        color: #E33D3D; }
  .cartBox .total-price {
    padding-bottom: 2rem;
    padding-top: 1rem; }
    .cartBox .total-price .hr {
      background: black;
      height: 1px;
      width: 100%;
      margin: 1rem 0; }
    .cartBox .total-price h4 {
      color: #222222;
      font-weight: 600;
      line-height: 1.2rem;
      font-size: 1rem; }
      .cartBox .total-price h4 span {
        color: #E33D3D; }
    .cartBox .total-price h6 {
      font-size: 1rem;
      line-height: 1.2rem;
      font-weight: 400;
      color: #222222; }
      .cartBox .total-price h6::after {
        display: none; }
    .cartBox .total-price h5 {
      font-size: 1.3rem;
      line-height: 1.5rem;
      margin: 0;
      font-weight: 600; }
      .cartBox .total-price h5::after {
        display: none; }
      .cartBox .total-price h5 span {
        color: #E33D3D; }
  .cartBox ul {
    padding-left: 1.3rem; }
    .cartBox ul .delivery input {
      float: left;
      top: 0.7rem;
      margin-right: 0; }
    .cartBox ul .delivery p {
      margin-left: 1rem;
      margin-bottom: 0;
      padding-top: .8rem; }
    .cartBox ul li {
      font-size: 1rem;
      line-height: 1.2rem;
      color: #222222;
      list-style: none;
      display: block;
      margin-bottom: 1rem;
      text-align: start; }
      .cartBox ul li input {
        top: 4px;
        outline: none;
        -webkit-appearance: none;
        background-color: #ffffff;
        border: 2px solid #CCCCCC;
        padding: 0.45rem;
        border-radius: 2.5rem;
        display: inline-block;
        position: relative;
        margin-right: 1rem; }
      .cartBox ul li input:checked:after {
        content: ' ';
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 2.5rem;
        position: absolute;
        top: 0.2rem;
        background: #E33D3D;
        left: 0.2rem;
        font-size: 1.6rem;
        outline: none; }
      .cartBox ul li:checked {
        background-color: #e9ecee;
        color: #99a1a7;
        border: 1px solid #adb8c0; }
  .cartBox .title .bottomB {
    padding-left: 1.5rem; }
    .cartBox .title .bottomB h4 {
      font-size: 1rem;
      line-height: 1.2rem;
      font-weight: 400;
      font-weight: 400; }
  .cartBox .price {
    margin-top: 2rem;
    text-align: end;
    padding-right: 0.7rem; }
    .cartBox .price h4 {
      font-size: 1.2rem;
      line-height: 1.3rem;
      color: red; }
    .cartBox .price p {
      font-size: 0.8rem;
      line-height: 1rem;
      color: #646464; }
  .cartBox .image {
    padding-top: 1rem;
    padding-bottom: 1rem; }
    .cartBox .image span {
      color: #E33D3D;
      font-weight: 600;
      padding-right: 0.75rem;
      margin-top: auto;
      margin-bottom: auto; }
  .cartBox .title-image {
    padding-top: 1rem; }
    .cartBox .title-image h3 {
      font-size: 1rem;
      font-family: "Merriweather", serif;
      display: block;
      margin-bottom: 0.35rem; }
    .cartBox .title-image p {
      display: block;
      font-size: .9rem;
      line-height: 1.1rem;
      margin-bottom: 0.25rem; }
    .cartBox .title-image h2 {
      font-size: 1rem;
      line-height: 1.2rem;
      color: #646464;
      margin: 0;
      padding: 0;
      font-weight: 400; }
  .cartBox .quantity {
    margin-top: 2.6rem;
    position: relative;
    background-color: white;
    border: 1px solid #646464;
    border-radius: 10px;
    max-width: 5.4rem;
    height: 2.3rem; }
    .cartBox .quantity label {
      position: absolute;
      left: 1rem;
      top: -0.6rem;
      background-color: #fff;
      font-size: 0.9rem;
      line-height: 1rem; }
    .cartBox .quantity h2 {
      font-size: 0.9rem;
      margin: auto; }
    .cartBox .quantity .btnn {
      color: #222222;
      background-color: transparent;
      border: none;
      outline: inherit;
      padding: 0;
      font-size: 1.1rem;
      line-height: 1.6rem;
      margin: auto; }

.finishButton .btn {
  color: #FFFFFF;
  background-color: #E33D3D;
  height: 2.5rem;
  width: 14rem;
  border: 0;
  border-radius: 0;
  outline: none;
  margin-bottom: 6.5rem; }
  .finishButton .btn:hover {
    color: #E33D3D;
    background-color: #ffffff;
    border: 1px solid #E33D3D; }

.bottomB {
  border-bottom: 1px solid black;
  width: 100%; }

.wrapperMobleCart {
  background-color: #FFFFFF;
  background-color: #FFFFFF;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  padding: .5rem;
  margin-bottom: 2rem; }
  .wrapperMobleCart .cartBoxMobile {
    border-bottom: 1px solid #646464;
    padding-top: 1rem;
    padding-bottom: 1rem; }
    .wrapperMobleCart .cartBoxMobile span {
      color: #E33D3D;
      margin-top: auto;
      margin-bottom: auto;
      font-size: 1.5rem;
      margin-right: .5rem; }
    .wrapperMobleCart .cartBoxMobile .mimage {
      max-width: 100%;
      height: 140px;
      object-fit: contain; }
    .wrapperMobleCart .cartBoxMobile .title-imageMobile h3 {
      font-size: 15px;
      color: #222222;
      font-family: "Merriweather", serif; }
    .wrapperMobleCart .cartBoxMobile .title-imageMobile .type h6 {
      font-size: 14px;
      color: #646464;
      line-height: 15px;
      text-align: end;
      margin-right: 2rem; }
    .wrapperMobleCart .cartBoxMobile .wrapper .quantity {
      float: left;
      margin-top: 12px;
      border: 1px solid #646464;
      border-radius: 10px;
      width: 75px;
      height: 35px; }
      .wrapperMobleCart .cartBoxMobile .wrapper .quantity h2 {
        font-size: 18px;
        margin: auto; }
      .wrapperMobleCart .cartBoxMobile .wrapper .quantity .btnn {
        color: #222222;
        background-color: transparent;
        border: none;
        outline: inherit;
        padding: 0;
        font-size: 1.3rem;
        line-height: 1.6rem;
        margin: auto; }
    .wrapperMobleCart .cartBoxMobile .wrapper .price {
      padding-right: 5px;
      text-align: end;
      padding-top: 25px; }
      .wrapperMobleCart .cartBoxMobile .wrapper .price h4 {
        font-size: 18px;
        color: #E33D3D; }

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .cartBox {
    padding: 1.5rem .5rem 1.5rem .8rem !important; } }

.loginguest {
  background-color: #FFFFFF;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  width: 100%;
  margin-top: 3rem;
  margin-bottom: 3rem;
  padding: 1.5rem 0 0 2.5rem;
  background-color: #fff; }
  .loginguest h1 {
    font-size: 1.3rem;
    line-height: 1.65rem;
    color: #222222;
    font-family: "Merriweather", serif;
    margin: 0; }
    .loginguest h1::after {
      content: '';
      background-color: #646464;
      width: 100%;
      height: 1px;
      display: block;
      margin-top: 0.25rem; }
  .loginguest h2 {
    font-size: 1.3rem;
    line-height: 1.65rem;
    color: #222222;
    font-family: "Merriweather", serif;
    margin: 0;
    padding-right: 3.75rem; }
    .loginguest h2::after {
      content: '';
      background-color: #646464;
      width: 100%;
      height: 1px;
      display: block;
      margin-top: 0.25rem; }
  .loginguest input {
    padding: .375rem 0 .375rem 0 !important;
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid #646464;
    margin-top: 2rem; }
    .loginguest input::-webkit-input-placeholder {
      color: #B5B5B5; }
    .loginguest input::-moz-placeholder {
      color: #B5B5B5; }
    .loginguest input::-ms-input-placeholder {
      color: #B5B5B5; }
    .loginguest input::placeholder {
      color: #B5B5B5; }
  .loginguest a {
    font-size: 1rem;
    line-height: 1.2rem;
    display: block;
    text-decoration: underline;
    color: #222222;
    margin-bottom: 2rem; }
    .loginguest a:hover {
      color: #222222; }
  .loginguest button {
    background-color: #E33D3D;
    color: #fff;
    font-family: "Merriweather", serif;
    height: 2.5rem;
    width: 10rem;
    border: 0;
    border-radius: 0; }
    .loginguest button:hover {
      color: #E33D3D;
      background-color: #fff;
      border: 1px solid #E33D3D; }
  .loginguest .btn2 {
    margin-top: 1rem;
    margin-bottom: 3rem; }
  .loginguest .deliveryAddress {
    display: flex;
    padding-right: 3.75rem;
    margin-top: 2rem; }
    .loginguest .deliveryAddress .form-group {
      position: relative;
      outline: none; }
      .loginguest .deliveryAddress .form-group .form-control {
        border-radius: 0;
        color: #222222;
        font-size: 1rem;
        width: 38rem;
        height: 2.5rem;
        border: 0.5px solid black; }
      .loginguest .deliveryAddress .form-group select {
        -webkit-appearance: none;
        -webkit-box-shadow: none !important;
        padding-right: 40px; }
      .loginguest .deliveryAddress .form-group svg {
        position: absolute;
        top: .8rem;
        right: .75rem;
        pointer-events: none; }
    .loginguest .deliveryAddress .unibutton {
      max-width: 9.5rem;
      margin-left: 1.75rem; }
  .loginguest .fields {
    padding-bottom: 3rem; }
    .loginguest .fields h3 {
      font-size: 1.5rem;
      line-height: 1.5rem;
      font-family: "Merriweather", serif;
      margin: 0; }
    .loginguest .fields input {
      margin-top: 1.8rem; }
    .loginguest .fields button {
      margin-top: 1rem; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .loginguest {
    padding: 2rem 1rem 0 1rem; }
    .loginguest .deliveryAddress {
      padding-right: .5rem;
      display: block; }
      .loginguest .deliveryAddress .form-group .form-control {
        font-size: 1rem;
        width: 100%; }
      .loginguest .deliveryAddress .unibutton {
        margin-left: 0; } }

@media screen and (max-width: 1366px) and (min-width: 1024px) {
  .loginguest .deliveryAddress .form-group .form-control {
    width: 34rem; } }

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .loginguest .deliveryAddress {
    display: block; }
    .loginguest .deliveryAddress .form-group .form-control {
      width: 100%; }
    .loginguest .deliveryAddress .unibutton {
      margin-left: 0; } }

.owerviewHeading {
  margin-top: 3rem;
  margin-bottom: 2rem;
  background-color: #FFFFFF;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  width: 100%; }
  .owerviewHeading .box-overwiew {
    padding: 1.5rem 3.75rem 0 2.5rem; }
    .owerviewHeading .box-overwiew h1 {
      font-family: "Merriweather", serif;
      font-size: 1.5rem;
      line-height: 1.9rem; }
      .owerviewHeading .box-overwiew h1::after {
        content: '';
        display: block;
        height: 1px;
        width: 100%;
        background-color: #222222;
        margin-bottom: 2.5rem; }
    .owerviewHeading .box-overwiew h4 {
      font-size: 1.25rem;
      line-height: 1.6rem;
      font-family: "Merriweather", serif;
      margin-bottom: 1rem;
      font-weight: 400; }
    .owerviewHeading .box-overwiew h6 {
      font-size: 1rem;
      line-height: 1.2rem;
      font-weight: 400;
      margin-bottom: .5rem; }
  .owerviewHeading .checkConditions {
    padding: 0 3.75rem 0 2.5rem;
    margin-top: .5rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem; }
    .owerviewHeading .checkConditions .input-wrap {
      padding-top: 1.5rem;
      border-top: 1px solid black; }
      .owerviewHeading .checkConditions .input-wrap label p {
        font-size: .9rem;
        line-height: 1.5rem;
        color: #222222;
        margin-left: 1rem; }
        .owerviewHeading .checkConditions .input-wrap label p span {
          color: #E33D3D;
          font-weight: 600;
          text-decoration: underline; }
  .owerviewHeading .order-prewiev {
    padding: 0 2.5rem 0 2.5rem; }
    .owerviewHeading .order-prewiev .title .bottomB {
      padding-left: 1.5rem; }
    .owerviewHeading .order-prewiev .content-prewiev {
      padding-bottom: 1rem;
      border-bottom: 1px solid #646464; }
      .owerviewHeading .order-prewiev .content-prewiev .item span {
        color: #E33D3D;
        margin-top: auto;
        margin-bottom: auto;
        margin-right: 0.75rem;
        font-size: 1.5rem; }
      .owerviewHeading .order-prewiev .content-prewiev .item img {
        padding-top: 1rem;
        max-width: 3.25rem; }
      .owerviewHeading .order-prewiev .content-prewiev .item .title-image {
        margin-left: .5rem;
        padding-top: 1rem; }
        .owerviewHeading .order-prewiev .content-prewiev .item .title-image h3 {
          font-size: 1rem;
          font-family: "Merriweather", serif;
          display: block;
          margin-bottom: 0.35rem; }
        .owerviewHeading .order-prewiev .content-prewiev .item .title-image p {
          display: block;
          font-size: .9rem;
          line-height: 1.1rem;
          margin-bottom: 0.25rem; }
        .owerviewHeading .order-prewiev .content-prewiev .item .title-image h2 {
          font-size: 1rem;
          line-height: 1.2rem;
          color: #646464;
          margin: 0;
          padding: 0;
          font-weight: 400; }
      .owerviewHeading .order-prewiev .content-prewiev .quantity {
        margin-top: 2.6rem;
        margin-right: .8rem;
        float: right;
        position: relative;
        background-color: white;
        border: 1px solid #646464;
        border-radius: 10px;
        min-width: 5.4rem;
        height: 2.3rem; }
        .owerviewHeading .order-prewiev .content-prewiev .quantity label {
          position: absolute;
          left: 1rem;
          top: -0.6rem;
          background-color: #fff;
          font-size: 0.9rem;
          line-height: 1rem; }
        .owerviewHeading .order-prewiev .content-prewiev .quantity h2 {
          font-size: 0.9rem;
          margin: auto; }
        .owerviewHeading .order-prewiev .content-prewiev .quantity .btnn {
          color: #222222;
          background-color: transparent;
          border: none;
          outline: inherit;
          padding: 0;
          font-size: 1.1rem;
          line-height: 1.6rem;
          margin: auto; }
      .owerviewHeading .order-prewiev .content-prewiev .price {
        margin-top: 2rem;
        text-align: end;
        padding-right: 0.7rem; }
        .owerviewHeading .order-prewiev .content-prewiev .price h4 {
          font-size: 1.2rem;
          line-height: 1.3rem;
          color: red; }
        .owerviewHeading .order-prewiev .content-prewiev .price p {
          font-size: 0.8rem;
          line-height: 1rem;
          color: #646464; }
    .owerviewHeading .order-prewiev .total-price {
      padding-bottom: 2rem;
      padding-top: 1rem; }
      .owerviewHeading .order-prewiev .total-price .hr {
        background: black;
        height: 1px;
        width: 100%;
        margin-bottom: 1rem; }
      .owerviewHeading .order-prewiev .total-price h4 {
        color: #222222;
        font-weight: 600;
        line-height: 1.2rem;
        font-size: 1rem; }
        .owerviewHeading .order-prewiev .total-price h4 span {
          color: #E33D3D; }
      .owerviewHeading .order-prewiev .total-price h6 {
        font-size: 1rem;
        line-height: 1.2rem;
        font-weight: 400;
        color: #222222; }
      .owerviewHeading .order-prewiev .total-price h5 {
        font-size: 1.3rem;
        line-height: 1.5rem;
        font-weight: 600; }
        .owerviewHeading .order-prewiev .total-price h5 span {
          color: #E33D3D; }
  .owerviewHeading .wrapperMobleCart {
    box-shadow: none !important; }

.bb .btn {
  color: #FFFFFF;
  background-color: #E33D3D;
  border: 0;
  border-radius: 0;
  font-family: "Merriweather", serif;
  height: 2.5rem;
  width: 9.5rem;
  margin-bottom: 6.5rem; }

.ukupno-price {
  padding-top: 1rem; }
  .ukupno-price .hr {
    background-color: #222222;
    width: 100%;
    height: 1px;
    margin-top: .5rem;
    margin-bottom: .5rem; }
  .ukupno-price h4 {
    font-size: 1rem;
    font-weight: 600; }
    .ukupno-price h4 span {
      color: #E33D3D; }
  .ukupno-price h6 {
    font-size: 1rem;
    line-height: 1.2rem; }
  .ukupno-price h5 {
    font-size: 1.3rem;
    line-height: 1.5rem;
    font-weight: 600; }
    .ukupno-price h5 span {
      color: #E33D3D; }

.wrapperMobleCart .cartBoxMobile .wrapper {
  width: 100%; }
  .wrapperMobleCart .cartBoxMobile .wrapper .quantity {
    float: left; }
  .wrapperMobleCart .cartBoxMobile .wrapper .price {
    float: right;
    text-align: end;
    justify-content: end; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .owerviewHeading .box-overwiew {
    padding: 1rem; }
  .owerviewHeading .checkConditions {
    padding: 1rem 1rem 0 1rem; }
    .owerviewHeading .checkConditions .input-wrap label {
      margin-top: 7px; } }

.search-background {
  background-color: #e0e0e0;
  padding: 25px 0;
  text-align: center; }
  .search-background .search {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: 730px;
    z-index: 2; }
    .search-background .search input {
      width: 100%;
      border-radius: 30px;
      border: 0;
      outline: none;
      height: 57px;
      padding-left: 25px;
      padding-right: 60px;
      position: relative; }
      .search-background .search input::-webkit-input-placeholder {
        font-size: 18px;
        color: #646464;
        font-weight: 400; }
      .search-background .search input::-moz-placeholder {
        font-size: 18px;
        color: #646464;
        font-weight: 400; }
      .search-background .search input::-ms-input-placeholder {
        font-size: 18px;
        color: #646464;
        font-weight: 400; }
      .search-background .search input::placeholder {
        font-size: 18px;
        color: #646464;
        font-weight: 400; }
    .search-background .search .no-bottom-radius {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px; }
    .search-background .search svg {
      position: absolute;
      top: 18px;
      right: 23px; }

